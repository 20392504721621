<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <v-dialog v-model="noAuthDialog" persistent width="300">
        <v-card>
          <v-card-title class="headline grey lighten-2"> 已登出 </v-card-title>
          <v-card-text class="my-4"> 請返回登入頁面 </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" href="/admin/login" text> 確定 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row align="center" justify="center">
        <v-col align="center" justify="center">
          <v-card style="max-width: 1000px" class="mx-4 my-4">
            <v-row align="center" justify="center">
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(coin, index) in coinsData" :key="coin.name">
                        <td class="text-h5">
                          <div>
                            {{ coin.name }}
                          </div>
                        </td>
                        <td v-if="index != 7" class="text-h5">
                          <div
                            :class="[
                              (index == 2 || index == 1) && isPriceUp ? 'priceUp' : '',
                              (index == 2 || index == 1) && !isPriceUp ? 'priceDown' : '',
                            ]"
                          >
                            {{ coin.data }}
                          </div>
                        </td>
                        <td v-else class="text-h5">
                          <v-chip class="ma-2" :color="'green'" outlined>
                            <v-icon left> mdi-check </v-icon>

                            {{ coin.data }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="4" align="center" justify="center"
                >目標價
                <v-text-field
                  v-model="targetPrice"
                  outlined
                  class="text-h5 mx-4"
                  style="max-width: 100"
                  prefix="$"
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="center" justify="center">
                成交F
                <v-text-field
                  v-model="targetVol"
                  outlined
                  class="text-h5 mx-4"
                  style="max-width: 100"
                  prefix="x"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn @click="setTargetPrice" elevation="2" x-large color="primary">
              提交
            </v-btn>
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center"> </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
        <v-progress-circular
          v-if="snackbar.loading"
          :width="3"
          color="green"
          indeterminate
          class="mx-4"
        ></v-progress-circular>
        {{ snackbar.message }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from "../axios";
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    sideBar,
    topToolBar,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      snackbar: {
        visible: false,
        loading: false,
        color: "",
        message: "",
        timeout: "",
      },

      noAuthDialog: false,
      isBotWorking: true,
      dataLoadTimer: "",
      firstLoadData: true,
      coinsData: [
        {
          name: "現價",
          data: "0",
        },
        {
          name: "漲跌 (24h)",
          data: "0",
        },
        {
          name: "漲跌百份比 (24h)",
          data: "0",
        },
        {
          name: "最高價 (24h)",
          data: "0",
        },
        {
          name: "最低價 (24h)",
          data: "0",
        },
        {
          name: "成交量 (24h)",
          data: "0",
        },
        {
          name: "成交量 USDT (24h)",
          data: "0",
        },
        {
          name: "狀態",
          data: "0",
        },
      ],
      targetPrice: "0",
      targetVol: "0",
      startBtnLoading: false,
      closeBtnLoading: false,
    };
  },
  computed: {
    ...mapGetters(["authGetter"]),
    isStartBtnDisabled() {
      return this.coinsData[7].data == "運行中";
    },
    isStopBtnDisabled() {
      return this.coinsData[7].data == "關閉";
    },
    isPriceUp() {
      if (parseFloat(this.coinsData[2].data) >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getCoinOverviewAdmin: function () {
      axios
        .post("/admin/coin", {
          coin_symbol: "BCHVCUSDT",
        })
        .then((res) => {
          console.log(res.data.coin);
          this.coinsData[0].data = res.data.coin.current_price;
          this.coinsData[1].data = res.data.coin.change_24h;
          this.coinsData[2].data = res.data.coin.change_pct_24h + "%";
          this.coinsData[3].data = res.data.coin.highest_24h;
          this.coinsData[4].data = res.data.coin.lowest_24h;
          this.coinsData[5].data = res.data.coin.vol_24h;
          this.coinsData[6].data = res.data.coin.vol_usd_24h;
          this.coinsData[7].data = "運行中";
          if (this.firstLoadData) {
            this.targetPrice = res.data.coin.target_price;
            this.targetVol = res.data.coin.vol_multiplier;
            this.firstLoadData = false;
          }
        });
    },
    setTargetPrice: function () {
      this.snackbar.visible = true;
      this.snackbar.loading = true;
      this.snackbar.timeout = 20000;
      this.snackbar.message = "設置目標價中";
      this.snackbar.color = "";
      axios
        .post("/admin/setPrice", {
          coin_symbol: "BCHVCUSDT",
          price: this.targetPrice,
          vol: this.targetVol,
        })
        .then((response) => {
          if (response.data.status) {
            this.snackbar.visible = true;
            this.snackbar.loading = false;
            this.snackbar.timeout = 5000;
            this.snackbar.message = response.data.message;
            this.snackbar.color = "green";
            this.getCoinOverviewAdmin();
          }
        });
      /*  CoinService.setTargetPrice_AIWF(this.targetPrice).then((response) => {
        if (response.data.status) {
          this.snackbar.visible = true;
          this.snackbar.loading = false;
          this.snackbar.timeout = 5000;
          this.snackbar.message = response.data.message;
          this.snackbar.color = "green";
          this.getCoinOverview();
        }
      }); */
    },
  },
  mounted() {
    this.getCoinOverviewAdmin();
    this.dataLoadTimer = setInterval(this.getCoinOverviewAdmin, 2000);
  },
  beforeDestroy() {
    clearInterval(this.dataLoadTimer);
  },
};
</script>
<style>
.priceDown {
  max-width: 170px;
  padding: 4px;
  border-color: #ff3333;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #ff3333;
  color: white;
}
.priceUp {
  max-width: 170px;
  padding: 4px;
  border-color: #00e600;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #00e600;
  color: white;
}
</style>
