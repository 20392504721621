<template>
  <v-card tile elevation="3">
    <v-toolbar dark>
      <v-toolbar-title>{{ currMenuTitle }}</v-toolbar-title>
    </v-toolbar>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      currMenuTitle: "",
    };
  },
  methods: {
    getCurrMenu: function () {
      switch (this.$router.currentRoute.name) {
        case "CoinPanel_BCHVC":
          this.currMenuTitle = "AITS";
          break;
        case "CoinPanel_AIWF":
          this.currMenuTitle = "AIWF";
          break;
        case "UserPanel":
          this.currMenuTitle = "Customer";
          break;
        case "DepositPanel":
          this.currMenuTitle = "Deposit";
          break;
        case "WithdrawalPanel":
          this.currMenuTitle = "Withdrawal";
          break;
        case "EmailPanel":
          this.currMenuTitle = "Send-Email";
          break;
        case "MailBox":
          this.currMenuTitle = "mailBox";
          break;
        case "StakingProfit":
          this.currMenuTitle = "Staking Rate";
          break;
      }
    },
  },
  created() {
    this.getCurrMenu();
  },
};
</script>
